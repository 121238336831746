import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import {
  CButton,
  CCloseButton,
  CForm,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
} from '@coreui/react-pro'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { culturedGrey } from '@constants/colors'
import { IAddLinkModal } from '@interfaces/components/organisms/AddLinkModal'

const AddLinkModal: React.FC<IAddLinkModal> = ({
  isVisible,
  onClose,
  onSave,
  content,
  selectionStart = 0,
  selectionEnd = 0,
}) => {
  const schema = yup.object().shape({
    text: yup.string(),
    link: yup.string().required('Required field'),
  })

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  useEffect(() => {
    if (!isVisible) {
      reset()
    } else if (content) {
      setValue('text', content)
    }
  }, [content, isVisible, reset, selectionEnd, selectionStart, setValue])

  const onSubmitForm = useCallback(() => {
    const { link, text } = getValues()
    const newText = `[${text || link}](${link})`
    onSave(newText)
  }, [getValues, onSave])

  return (
    <CModal visible={isVisible} onClose={onClose} backdrop="static" alignment="center" size="sm">
      <CModalBody className="body-small-font" style={{ backgroundColor: culturedGrey }}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="subheader-font">Add link</div>
          <CCloseButton onClick={onClose} />
        </div>
        <CForm onSubmit={handleSubmit(onSubmitForm)}>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <div className="FormInputTextContainer mb-3">
                <CFormLabel className="FormInputTextLabel body-small-font">Text</CFormLabel>
                <CFormInput
                  size="sm"
                  value={field.value || ''}
                  onChange={field.onChange}
                  invalid={!!errors.text}
                  feedbackInvalid={errors.link?.message}
                />
              </div>
            )}
          />
          <Controller
            name="link"
            control={control}
            render={({ field }) => (
              <div className="FormInputTextContainer">
                <CFormLabel className="FormInputTextLabel body-small-font">Link</CFormLabel>
                <CFormInput
                  size="sm"
                  value={field.value || ''}
                  onChange={field.onChange}
                  invalid={!!errors.link}
                  feedbackInvalid={errors.link?.message}
                />
              </div>
            )}
          />
        </CForm>
        <div className="d-flex mt-3">
          <CButton color="secondary me-3" onClick={onClose}>
            Cancel
          </CButton>
          <CButton color="primary" onClick={handleSubmit(onSubmitForm)}>
            Save
          </CButton>
        </div>
      </CModalBody>
    </CModal>
  )
}

export default AddLinkModal
