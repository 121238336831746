import React, { createContext, useContext, useState } from 'react'

import { ISavedFormProvider } from '@interfaces/contexts/savedFormProvider'
import { DefaultTemplatesQueryElement } from '@interfaces/general'

const SavedFormContext = createContext<ISavedFormProvider>({
  savedForm: undefined,
  setSaveForm: (_form?: DefaultTemplatesQueryElement) => {},
})

export const useSavedForm = () => {
  return useContext(SavedFormContext)
}

export const SavedFormProvider = ({ children }: { children?: React.ReactNode }) => {
  const [savedForm, setSaveForm] = useState<DefaultTemplatesQueryElement>()

  return (
    <SavedFormContext.Provider value={{ savedForm, setSaveForm }}>
      {children}
    </SavedFormContext.Provider>
  )
}
