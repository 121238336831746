// Add color to _colors.scss as well
export const white = '#ffffff'

export const black = '#000000'
export const blackPearl = '#22292C'
export const blackFont = '#2C384AF2'

// purple colors
export const indigo = '#400099'
export const middlePurple = '#9676C3'
export const grape = '#594474'

// blue colors
export const tiffanyBlue = '#24d9d9'
export const middleBlue = '#91c3c8'
export const azureX = '#DDE9E8'
export const carolinaBlue = '#449dd1'
export const alertBlue = '#F0F6F9'
export const pictonBlue = '#449DD1'
export const skyBlue = '#5C9CC1'
export const blueC4 = '#C4DBE9'

// grey colors
export const battleshipGrey = '#878a8c'
export const greyX = '#bdbdbd'
export const lightGrey = '#d5d5d5'
export const culturedGrey = '#f2f2f2'

// red colors
export const red = '#D92424'
export const errorRed = '#fcebe6'
export const pink = '#FCEEF5'
export const corvette = '#E788B6'
export const purpleF4 = '#F4F1F9'

// green colors
export const lightGreen = '#F1F6F6'
export const darkGreen = '#3E747A'
export const greenF3 = '#F3FBF2'

// yellow colors
export const goldenRod = '#DBA000'
export const sun1 = '#FFFAEB'
export const yellowFF = '#FFEBAD'

// emoji colors
export const emoji1 = '#edd078'
export const emoji2 = '#f4db91'
export const emoji3 = '#f1e0ad'
export const emoji4 = '#c8d3c4'
export const emoji5 = '#b0dcc9'
export const emoji6 = '#89deb9'
export const emoji7 = '#76d5ab'

// level
export const low = '#57CC99'
export const mild = '#DBC203'
export const moderate = '#FFBC33'
export const elevated = '#D92424'

export const low2 = '#346983'
export const mild2 = '#93AA88'
export const moderate2 = '#FFD147'
export const elevated2 = '#BA266E'

export const low2Text = '#346983'
export const mild2Text = '#566B4C'
export const moderate2Text = '#A37A00'
export const elevated2Text = '#771846'
