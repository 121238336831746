import React, { createContext, useContext, useState } from 'react'

import { ICustomRouteContext } from '../interfaces/contexts/ICustomRouteContext'

const CustomRouteContext = createContext<ICustomRouteContext>({
  customRoute: '',
  handleCustomRoute: (value: string) => {},
})

export const useCustomRoute = () => {
  return useContext(CustomRouteContext)
}

export const CustomRouteProvider = ({ children }: { children?: React.ReactNode }) => {
  const [customRoute, setCustomRoute] = useState<string | null>()

  const handleCustomRoute = (value: string) => {
    setCustomRoute(value)
  }

  return (
    <CustomRouteContext.Provider value={{ customRoute, handleCustomRoute }}>
      {children}
    </CustomRouteContext.Provider>
  )
}
