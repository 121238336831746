import { differenceInDays } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

import { WalkthroughType } from '@interfaces/components/organisms/Walkthrough'

const TRY_APP_DONE = 'TRY_APP_DONE'
export const setTryAppDone = () => {
  localStorage.setItem(TRY_APP_DONE, 'true')
}
export const getTryAppDone = () => {
  const result = localStorage.getItem(TRY_APP_DONE)
  return result === 'true' ? true : false
}

const WELCOME_PAGE_VIEWED = 'WELCOME_PAGE_VIEWED'
export const setWelcomePageViewed = () => {
  localStorage.setItem(WELCOME_PAGE_VIEWED, 'true')
}
export const getWelcomePageViewed = () => {
  const result = localStorage.getItem(WELCOME_PAGE_VIEWED)
  return result === 'true' ? true : false
}

const ACTIVATE_2FA_MODAL_VIEWED = 'ACTIVATE_2FA_MODAL_VIEWED'
export const getActivate2FAModalViewed = () => {
  const data: { viewedTimes?: number; lastViewDate?: number } = JSON.parse(
    localStorage.getItem(ACTIVATE_2FA_MODAL_VIEWED) || '{}',
  )
  return data
}
export const setActivate2FAModalViewed = () => {
  const data = getActivate2FAModalViewed()
  localStorage.setItem(
    ACTIVATE_2FA_MODAL_VIEWED,
    JSON.stringify({
      lastViewDate: Date.now(),
      viewedTimes: data.viewedTimes ? data.viewedTimes + 1 : 1,
    }),
  )
}
export const shouldShowActivate2FAModal = () => {
  const data = getActivate2FAModalViewed()
  if (
    data &&
    data.viewedTimes &&
    data.lastViewDate &&
    (data.viewedTimes >= 3 || differenceInDays(data.lastViewDate, Date.now()) < 7)
  ) {
    return false
  }
  return true
}

const SELF_CARE_PLAN_INCENTIVE_VIEWED = 'SELF_CARE_PLAN_INCENTIVE_VIEWED'
export const setSelfCarePlanIncentiveViewed = () => {
  localStorage.setItem(SELF_CARE_PLAN_INCENTIVE_VIEWED, 'true')
}
export const getSelfCarePlanIncentiveViewed = () => {
  const result = localStorage.getItem(SELF_CARE_PLAN_INCENTIVE_VIEWED)
  return result === 'true' ? true : false
}

const WALKTHROUGH_VIEWED = 'WALKTHROUGH_VIEWED'
export const setWalkthroughViewed = (type: WalkthroughType) => {
  const data = JSON.parse(localStorage.getItem(WALKTHROUGH_VIEWED) || '{}')
  localStorage.setItem(
    WALKTHROUGH_VIEWED,
    JSON.stringify({
      ...data,
      [type]: true,
    }),
  )
}
export const getWalkthroughViewed = (type: WalkthroughType): boolean => {
  const result = localStorage.getItem(WALKTHROUGH_VIEWED)
  return JSON.parse(result || '{}')[type]
}

const DEFAULT_SELF_CARE_PLAN_MODAL_VIEWED = 'DEFAULT_SELF_CARE_PLAN_MODAL_VIEWED'
export const setDefaultSelfCarePlanModalViewed = () => {
  localStorage.setItem(DEFAULT_SELF_CARE_PLAN_MODAL_VIEWED, 'true')
}
export const getDefaultSelfCarePlanModalViewed = () => {
  const result = localStorage.getItem(DEFAULT_SELF_CARE_PLAN_MODAL_VIEWED)
  return result === 'true' ? true : false
}

const WAITING_LIST_JOINED = 'WAITING_LIST_JOINED'
export const setWaitingListJoined = () => {
  localStorage.setItem(WAITING_LIST_JOINED, 'true')
}
export const getWaitingListJoined = () => {
  const result = localStorage.getItem(WAITING_LIST_JOINED)
  return result === 'true' ? true : false
}

const DESKTOP_MODAL_SHOWED = 'DESKTOP_MODAL_SHOWED'
export const setDesktopModalShowed = () => {
  localStorage.setItem(DESKTOP_MODAL_SHOWED, 'true')
}
export const getDesktopModalShowed = () => {
  const result = localStorage.getItem(DESKTOP_MODAL_SHOWED)
  return result === 'true' ? true : false
}

const ACTIVITY_PREVIEW_TIMES = 'ACTIVITY_PREVIEW_TIMES'
export const getActivityPreviewTimes = () => {
  const result = localStorage.getItem(ACTIVITY_PREVIEW_TIMES) || '0'

  return +result
}
export const incrementActivityPreviewTimes = () => {
  const oldValue = getActivityPreviewTimes()
  const newValue = oldValue + 1
  localStorage.setItem(ACTIVITY_PREVIEW_TIMES, newValue.toString())
}

const FORM_BUILDER_EMAIL = 'FORM_BUILDER_EMAIL'
export const setFormBuilderEmail = (email: string) => {
  localStorage.setItem(FORM_BUILDER_EMAIL, email)
}
export const getFormBuilderEmail = () => {
  return localStorage.getItem(FORM_BUILDER_EMAIL)
}
export const deleteFormBuilderEmail = () => {
  return localStorage.setItem(FORM_BUILDER_EMAIL, '')
}

const SEND_TO_SELF_INCENTIVE_HIDDEN = 'SEND_TO_SELF_INCENTIVE_HIDDEN'
export const setSendToSelfIncentiveHidden = () => {
  localStorage.setItem(SEND_TO_SELF_INCENTIVE_HIDDEN, 'true')
}
export const getSendToSelfIncentiveHidden = () => {
  const result = localStorage.getItem(SEND_TO_SELF_INCENTIVE_HIDDEN)
  return result === 'true' ? true : false
}

const SIGN_UP_PHONE_NUMBER = 'SIGN_UP_PHONE_NUMBER'
export const setSignUpPhoneNumber = (phoneNumber: string) => {
  localStorage.setItem(SIGN_UP_PHONE_NUMBER, phoneNumber)
}
export const getSignUpPhoneNumber = () => {
  return localStorage.getItem(SIGN_UP_PHONE_NUMBER)
}
export const deleteSignUpPhoneNumber = () => {
  return localStorage.removeItem(SIGN_UP_PHONE_NUMBER)
}

const GUEST_ID = 'GUEST_ID'
export const getGuestId = () => {
  let guestId = localStorage.getItem(GUEST_ID)
  if (!guestId) {
    guestId = uuidv4()
    localStorage.setItem(GUEST_ID, guestId)
  }
  return guestId
}

const CHAT_MESSAGE_INCENTIVE_SHOWN = 'CHAT_MESSAGE_INCENTIVE_SHOWN'
export const setChatMessageIncentiveShown = () => {
  localStorage.setItem(CHAT_MESSAGE_INCENTIVE_SHOWN, 'true')
}
export const getChatMessageIncentiveShown = () => {
  const result = localStorage.getItem(CHAT_MESSAGE_INCENTIVE_SHOWN)
  return result === 'true' ? true : false
}
