import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'

import { CToast, CToastBody, CToastClose, CToaster } from '@coreui/react-pro'
import { mdiAlert, mdiAlertCircle, mdiCheckCircle } from '@mdi/js'
import Icon from '@mdi/react'

type ToastContent = {
  message: string
  placement?:
    | 'top-start'
    | 'top-center'
    | 'top-end'
    | 'middle-start'
    | 'middle-center'
    | 'middle-end'
    | 'bottom-start'
    | 'bottom-center'
    | 'bottom-end'
  state?: 'success' | 'warning' | 'error'
  autohide?: boolean
  delay?: number
}
const ToastContext = createContext<{
  show: (content: ToastContent) => void
}>({
  show: () => {},
})

export const useToast = () => {
  return useContext(ToastContext)
}

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toast, setToast] = useState<JSX.Element>()
  const [content, setContent] = useState<ToastContent>()

  const defaultComponentToast = useCallback(({ message, state, autohide, delay }: ToastContent) => {
    let style = 'toast-success'
    let icon = mdiCheckCircle
    if (state === 'warning') {
      style = 'toast-warning'
      icon = mdiAlert
    } else if (state === 'error') {
      style = 'toast-error'
      icon = mdiAlertCircle
    }

    return (
      <CToast
        visible
        autohide={autohide ?? true}
        delay={delay}
        className={`text-white align-items-center ${style}`}
      >
        <div className="d-flex align-items-center justify-content-between">
          <CToastBody className="d-flex">
            <div>
              <Icon path={icon} size={0.8} className="me-1" />
            </div>
            <div>{message}</div>
          </CToastBody>
          <CToastClose className="me-2 m-auto" />
        </div>
      </CToast>
    )
  }, [])

  const onShow = useCallback(
    (content: ToastContent) => {
      setContent(content)
      setToast(defaultComponentToast(content))
    },
    [defaultComponentToast],
  )

  return (
    <ToastContext.Provider value={{ show: onShow }}>
      <CToaster push={toast} placement={content?.placement || 'bottom-center'} hidden={false} />
      {children}
    </ToastContext.Provider>
  )
}
