import React, { FC, Suspense, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import 'react-international-phone/style.css'
import 'react-phone-number-input/style.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { useApolloClient } from '@apollo/client'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { jwtDecode } from 'jwt-decode'

import { Activate2FAModalProvider } from '@contexts/activate2FAModalProvider'
import { AddLinkModalProvider } from '@contexts/addLinkModalProvider'
import { ConfirmationModalProvider } from '@contexts/confirmationModalProvider'
import { SavedFormProvider } from '@contexts/savedFormProvider'
import { ToastProvider } from '@contexts/toastProvider'
import { analytics } from '@utils/segment'

import './assets/fonts/Metropolis-Bold.otf'
import './assets/fonts/Metropolis-BoldItalic.otf'
import './assets/fonts/Metropolis-Light.otf'
import './assets/fonts/Metropolis-LightItalic.otf'
import './assets/fonts/Metropolis-Medium.otf'
import './assets/fonts/Metropolis-MediumItalic.otf'
import './assets/fonts/Metropolis-Regular.otf'
import './assets/fonts/Metropolis-RegularItalic.otf'
import './assets/fonts/Metropolis-SemiBold.otf'
import './assets/fonts/Metropolis-SemiBoldItalic.otf'
import { AuthProvider, useAuth } from './contexts/authContext'
import { CustomRouteProvider } from './contexts/customRouteContext'

import './scss/style.scss'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./features/authentication/pages/Login'))
const ResetPassword = React.lazy(() => import('./features/authentication/pages/ResetPassword'))
const Register = React.lazy(() => import('./features/authentication/pages/Register'))
const Page404 = React.lazy(() => import('./features/errorPages/pages/Page404'))
const Page500 = React.lazy(() => import('./features/errorPages/pages/Page500'))
const DeferredDeepLink = React.lazy(
  () => import('@features/deferredDeepLink/pages/DeferredDeepLink'),
)
const FormBuilder = React.lazy(() => import('@features/CBTLibrary/pages/FormBuilder'))
const SubmitFrom = React.lazy(() => import('@features/CBTLibrary/pages/SubmitForm'))
const Loading = React.lazy(() => import('./features/authentication/pages/LoadingPage'))

const RequireAuth: FC = () => {
  const auth = useAuth()
  const location = useLocation()

  const client = useApolloClient()
  const navigate = useNavigate()

  useEffect(() => {
    // logout user if token has expired
    if (auth.token) {
      const payload = jwtDecode(auth.token)
      if (payload.exp && payload.exp * 1000 < Date.now()) {
        localStorage.removeItem('token')
        client.cache.reset().then(() => {
          auth.signout(() => {
            navigate('/login', { replace: true })
          })
          alert('Your session has expired. Please login again.')
        })
      }
    }
  }, [auth, client.cache, navigate])

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="login" state={{ from: location }} replace />
  }

  return <DefaultLayout />
}

const RouteChange: FC = () => {
  const location = useLocation()

  useEffect(() => {
    analytics.page()
  }, [location])

  return null
}

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <Elements stripe={stripePromise}>
          <ToastProvider>
            <CustomRouteProvider>
              <ConfirmationModalProvider>
                <AddLinkModalProvider>
                  <Activate2FAModalProvider>
                    <SavedFormProvider>
                      <BrowserRouter>
                        <RouteChange />
                        <Suspense fallback={loading}>
                          <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/404" element={<Page404 />} />
                            <Route path="/500" element={<Page500 />} />
                            <Route path="/deep-link" element={<DeferredDeepLink />} />
                            <Route path="/demo" element={<FormBuilder />} />
                            <Route path="/demo/:templateId" element={<FormBuilder />} />
                            <Route path="/demo-generator" element={<FormBuilder />} />
                            <Route path="/submit-form" element={<SubmitFrom />} />
                            <Route path="/loading" element={<Loading />} />
                            <Route path="*" element={<RequireAuth />} />
                            {/* redirect form builder to demo */}
                            <Route
                              path="/form-builder"
                              element={
                                <Navigate
                                  to={{
                                    pathname: '/demo',
                                    search: window.location.search,
                                  }}
                                  replace
                                />
                              }
                            />
                          </Routes>
                        </Suspense>
                      </BrowserRouter>
                    </SavedFormProvider>
                  </Activate2FAModalProvider>
                </AddLinkModalProvider>
              </ConfirmationModalProvider>
            </CustomRouteProvider>
          </ToastProvider>
        </Elements>
      </AuthProvider>
    </HelmetProvider>
  )
}

export default App
