import React, { createContext, useContext, useState } from 'react'

import { IAuthContext } from '@interfaces/contexts/authContext'

const AuthContext = createContext<IAuthContext>({
  token: undefined,
  signin: () => {},
  signout: () => {},
})

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const [token, setToken] = useState<string | undefined | null>(localStorage.getItem('token'))

  const signin = (token: string, callback: () => void) => {
    setToken(token)
    // add timeout to wait for setToken to finish
    setTimeout(() => callback(), 100)
  }

  const signout = (callback: () => void) => {
    setToken(undefined)
    // add timeout to wait for setToken to finish
    setTimeout(() => callback(), 100)
  }

  return (
    <AuthContext.Provider value={{ token, signin, signout }}> {children} </AuthContext.Provider>
  )
}
