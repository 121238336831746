import React, { useEffect } from 'react'

import { CButton, CCloseButton, CModal, CModalBody, CModalFooter } from '@coreui/react-pro'

import { ReactComponent as TwoFactorIcon } from '@assets/images/two-factor-icon.svg'
import { IActivate2FAModal } from '@interfaces/components/organisms/Activate2FAModal'
import { setActivate2FAModalViewed } from '@utils/localStorage'

const Activate2FAModal: React.FC<IActivate2FAModal> = ({ isVisible, onClose, onClickPositive }) => {
  useEffect(() => {
    if (isVisible) {
      setActivate2FAModalViewed()
    }
  }, [isVisible])

  return (
    <CModal visible={isVisible} onClose={onClose} alignment="center">
      <CModalBody>
        <div className="d-flex column align-items-center justify-content-center">
          <CCloseButton className="align-self-end" onClick={onClose} />
          <h5 className="text-center mb-4 mt-3">Please activate two-factor authentication</h5>
          <TwoFactorIcon className="mb-4" />
          <div className="body-large-font mb-2">
            By enabling 2FA, you&apos;ll have peace of mind knowing that your account is better
            protected against unauthorized access. Plus, it only takes a few minutes to set up!
          </div>
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={onClickPositive}>Protect my account now</CButton>
        <CButton color="secondary" onClick={onClose}>
          Close
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default Activate2FAModal
