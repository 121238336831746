export const analytics = window.analytics

export enum SegmentEvent {
  ACTIVITY_PREVIEWED = 'Activity Previewed',
  ADD_TEMPLATE_BUTTON_CLICKED = 'Add Template Button Clicked',
  UPGRADE_NOW_BUTTON_CLICKED = 'Upgrade Now Button Clicked',
  UPGRADE_PLAN_BUTTON_CLICKED = 'Upgrade Plan Button Clicked',
  BOOK_A_DEMO_BUTTON_CLICKED = 'Book A Demo Button Clicked',
  SEND_FORM_BUTTON_CLICKED = 'Send Form Button Clicked',
  GENERATE_FORM_BUTTON_CLICKED = 'Generate Form Button Clicked',
  JOIN_WAITING_LIST_BUTTON_CLICKED = 'Join Waiting List Button Clicked',
  FORM_RESPONSE_VIEWED = 'Form Response Viewed',
  FORM_RESPONSE_DOWNLOAD_PDF_BUTTON_CLICKED = 'Form Response Download PDF Button Clicked',
  DOWNLOAD_PDF_BUTTON_CLICKED = 'Download PDF Button Clicked',
  CLIENT_DOWNLOAD_PDF_BUTTON_CLICKED = 'Client Download PDF Button Clicked',
  RENAME_TEMPLATE_BUTTON_CLICKED = 'Rename Template Button Clicked',
  TEMPLATE_SEARCH_EMPTY_STATE_BUTTON_CLICKED = 'Template Search Empty State Button Clicked',
  FORM_RESPONSES_CSV_FILE_DOWNLOADED = 'Form Responses CSV File Downloaded',
  SELECT_PROMPT_VIEW_TOGGLED = 'Select Prompt View Toggled',
  PROMPT_EXAMPLE_SELECTED = 'Prompt Example Selected',
  EDITOR_ASSISTANT_BUTTON_CLICKED = 'Editor Assistant Button Clicked',
  CREATE_PAGE_TEMPLATE_TYPE_SELECTED = 'Create Page Template Type Selected',
  CREATE_PAGE_LIBRARY_TEMPLATE_TYPE_CHANGED = 'Create Page Library Template Type Changed',
  LIBRARY_PAGE_TEMPLATE_TYPE_SELECTED = 'Library Page Template Type Changed',

  FORM_BUILDER_PROMPT_EXAMPLE_SELECTED = 'Form Builder Prompt Example Selected',
  FORM_BUILDER_PAGE_VIEWED = 'Form Builder Page Viewed',
  FORM_BUILDER_ACTIVITY_PREVIEWED = 'Form Builder Activity Previewed',
  FORM_BUILDER_FORM_UPDATED = 'Form Builder Form Updated',
  FORM_BUILDER_FORM_TAB_CHANGED = 'Form Builder Form Tab Changed',
  FORM_BUILDER_SEND_TO_CLIENT_BUTTON_CLICKED = 'Form Builder Send To Client Button Clicked',
  FORM_BUILDER_ADD_TO_LIBRARY_BUTTON_CLICKED = 'Form Builder Add To Library Button Clicked',
  FORM_BUILDER_DOWNLOAD_PDF_BUTTON_CLICKED = 'Form Builder Download PDF Button Clicked',
  FORM_BUILDER_SHOW_LIBRARY_BUTTON_CLICKED = 'Form Builder Show Library Button Clicked',
  FORM_BUILDER_SIGN_UP_BUTTON_CLICKED = 'Form Builder Sign Up Button Clicked',
  FORM_BUILDER_SIGN_UP_MODAL_OPENED = 'Form Builder Sign Up Modal Opened',
  FORM_BUILDER_SHOW_PROMPT_HELP_BUTTON_CLICKED = 'Form Builder Show Prompt Help Button Clicked',
  FORM_BUILDER_REQUEST_ACTIVITY_BUTTON_CLICKED = 'Form Builder Request Activity Button Clicked',
  FORM_BUILDER_MOBILE_SHARE_BUTTON_CLICKED = 'Form Builder Mobile Share Button Clicked',
  FORM_BUILDER_MORE_FEE_OPTION_BUTTON_CLICKED = 'Form Builder More Fee option Button Clicked',
  FORM_BUILDER_EMAIL_PROVIDED = 'Form Builder Email Provided',
  FORM_BUILDER_EMAIL_MODAL_CLOSED = 'Form Builder Email Modal Closed',
  FORM_BUILDER_VIDEO_STARTED = 'Form Builder Video Started',
  FORM_BUILDER_VIDEO_ENDED = 'Form Builder Video Ended',
  FORM_BUILDER_SELECT_PROMPT_VIEW_TOGGLED = 'Form Builder Select Prompt View Toggled',
}
