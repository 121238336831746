import React, { ChangeEvent, useCallback, useState } from 'react'

import {
  CFormTextarea,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react-pro'
import { mdiTextBox } from '@mdi/js'
import Icon from '@mdi/react'

import { battleshipGrey } from '@constants/colors'
import { IConfirmationModal } from '@interfaces/components/organisms/ConfirmationModal'
import { ThemeColor } from '@interfaces/general'

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  isVisible,
  title,
  description,
  positiveButtonLabel,
  negativeButtonLabel = 'Close',
  onPressPositiveButton,
  onPressNegativeButton,
  positiveButtonLoading,
  negativeButtonLoading,
  positiveButtonColor = 'primary',
  negativeButtonColor = 'secondary',
  withNote = false,
  onClose,
  customBody = null,
}) => {
  const [note, setNote] = useState('')

  const _handlePressNegativeButton = useCallback(() => {
    if (onPressNegativeButton) {
      onPressNegativeButton()
    } else {
      onClose()
    }
  }, [onClose, onPressNegativeButton])

  const _getButtonTextStyle = useCallback((color: ThemeColor) => {
    if (color === 'danger') {
      return { color: 'white' }
    }
    return undefined
  }, [])

  const onChangeNote = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value)
  }, [])

  return (
    <CModal alignment="center" backdrop="static" visible={isVisible} onClose={onClose} scrollable>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {customBody ? (
          customBody
        ) : (
          <>
            <div className="body-large-font white-space-pre-wrap">{description}</div>
            {withNote && (
              <div className="d-flex align-items-center" style={{ marginTop: 50 }}>
                <Icon className="AAMIcon" path={mdiTextBox} size={1} color={battleshipGrey} />
                <CFormTextarea
                  onChange={onChangeNote}
                  value={note}
                  placeholder="Add a note for your clients"
                  rows={1}
                />
              </div>
            )}
          </>
        )}
      </CModalBody>
      <CModalFooter>
        <CLoadingButton
          className={negativeButtonColor === 'secondary' ? 'secondary' : ''}
          loading={negativeButtonLoading}
          color={negativeButtonColor}
          style={_getButtonTextStyle(negativeButtonColor)}
          onClick={_handlePressNegativeButton}
        >
          {negativeButtonLabel}
        </CLoadingButton>
        {positiveButtonLabel ? (
          <CLoadingButton
            loading={positiveButtonLoading}
            color={positiveButtonColor}
            style={_getButtonTextStyle(positiveButtonColor)}
            onClick={() => {
              onPressPositiveButton(note)
            }}
          >
            {positiveButtonLabel}
          </CLoadingButton>
        ) : null}
      </CModalFooter>
    </CModal>
  )
}

export default ConfirmationModal
