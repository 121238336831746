import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import Activate2FAModal from '@components/organisms/Activate2FAModal'

const Activate2FAModalContext = createContext<{
  setContent: (content: { onClickActivate: () => void; onClose: () => void }) => void
}>({
  setContent: () => {},
})

export const useActivate2FAModal = () => {
  return useContext(Activate2FAModalContext)
}

export const Activate2FAModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [content, setContent] = useState<{ onClickActivate: () => void; onClose: () => void }>()

  useEffect(() => {
    if (content) {
      setIsVisible(true)
    }
  }, [content])

  useEffect(() => {
    if (!isVisible) {
      setContent(undefined)
    }
  }, [isVisible])

  const onClose = () => {
    setIsVisible(false)
    if (content?.onClose) {
      content?.onClose()
    }
  }

  const handelOnClickPositive = () => {
    setIsVisible(false)
    if (content?.onClickActivate) {
      content?.onClickActivate()
    }
  }

  return (
    <Activate2FAModalContext.Provider value={{ setContent }}>
      <Activate2FAModal
        isVisible={isVisible}
        onClose={onClose}
        onClickPositive={handelOnClickPositive}
      />
      {children}
    </Activate2FAModalContext.Provider>
  )
}
