import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import ConfirmationModal from '@components/organisms/ConfirmationModal'
import { IConfirmationModal } from '@interfaces/components/organisms/ConfirmationModal'

const ConfirmationModalContext = createContext<{
  show: (
    content?: Partial<
      Omit<IConfirmationModal, 'isVisible' | 'positiveButtonLoading' | 'negativeButtonLoading'>
    >,
  ) => void
  hide: () => void
  setPositiveButtonLoading: (value: boolean) => void
  setNegativeButtonLoading: (value: boolean) => void
}>({
  show: () => {},
  hide: () => {},
  setPositiveButtonLoading: () => {},
  setNegativeButtonLoading: () => {},
})

export const useConfirmationModal = () => {
  return useContext(ConfirmationModalContext)
}

export const ConfirmationModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [positiveButtonLoading, setPositiveButtonLoading] = useState(false)
  const [negativeButtonLoading, setNegativeButtonLoading] = useState(false)

  const [content, setContent] =
    useState<
      Partial<
        Omit<IConfirmationModal, 'isVisible' | 'positiveButtonLoading' | 'negativeButtonLoading'>
      >
    >()

  useEffect(() => {
    if (content) {
      setIsVisible(true)
    }
  }, [content])

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setContent(undefined)
        setPositiveButtonLoading(false)
        setNegativeButtonLoading(false)
      }, 200)
    }
  }, [isVisible])

  const onClose = useCallback(() => {
    setIsVisible(false)
    if (content?.onClose) {
      content?.onClose()
    }
  }, [content])

  const onPressPositiveButton = useCallback(() => {
    if (content?.onPressPositiveButton) {
      content?.onPressPositiveButton()
    } else {
      onClose()
    }
  }, [content, onClose])

  const onPressNegativeButton = useCallback(() => {
    if (content?.onPressNegativeButton) {
      content?.onPressNegativeButton()
    } else {
      onClose()
    }
  }, [content, onClose])

  return (
    <ConfirmationModalContext.Provider
      value={{
        show: setContent,
        hide: onClose,
        setNegativeButtonLoading,
        setPositiveButtonLoading,
      }}
    >
      <ConfirmationModal
        isVisible={isVisible}
        onClose={onClose}
        withNote={content?.withNote}
        title={content?.title || ''}
        description={content?.description || ''}
        onPressPositiveButton={onPressPositiveButton}
        positiveButtonLabel={content?.positiveButtonLabel}
        positiveButtonColor={content?.positiveButtonColor}
        positiveButtonLoading={positiveButtonLoading}
        onPressNegativeButton={onPressNegativeButton}
        negativeButtonColor={content?.negativeButtonColor}
        negativeButtonLabel={content?.negativeButtonLabel}
        negativeButtonLoading={negativeButtonLoading}
        customBody={content?.customBody}
      />
      {children}
    </ConfirmationModalContext.Provider>
  )
}
