import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import AddLinkModal from '@components/organisms/AddLinkModal'
import { IAddLinkModal } from '@interfaces/components/organisms/AddLinkModal'

const AddLinkModalContext = createContext<{
  show: (content?: Omit<IAddLinkModal, 'isVisible'>) => void
  hide: () => void
}>({
  show: () => {},
  hide: () => {},
})

export const useAddLinkModal = () => {
  return useContext(AddLinkModalContext)
}

export const AddLinkModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const [content, setContent] = useState<Omit<IAddLinkModal, 'isVisible'>>()

  useEffect(() => {
    if (content) {
      setIsVisible(true)
    }
  }, [content])

  useEffect(() => {
    if (!isVisible) {
      setContent(undefined)
    }
  }, [isVisible])

  const onClose = useCallback(() => {
    setIsVisible(false)
    if (content?.onClose) {
      content?.onClose()
    }
  }, [content])

  const onSave = useCallback(
    (newContent: string) => {
      setIsVisible(false)
      if (content?.onSave) {
        content?.onSave(newContent)
      }
    },
    [content],
  )

  return (
    <AddLinkModalContext.Provider
      value={{
        show: setContent,
        hide: onClose,
      }}
    >
      <AddLinkModal
        isVisible={isVisible}
        onClose={onClose}
        onSave={onSave}
        content={content?.content || ''}
        selectionStart={content?.selectionStart}
        selectionEnd={content?.selectionEnd}
      />
      {children}
    </AddLinkModalContext.Provider>
  )
}
