import '@utils/sentryInitialization'

import React from 'react'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import 'core-js'

import { getGuestId } from '@utils/localStorage'
import { analytics } from '@utils/segment'

import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  const guestId = getGuestId()
  // return the headers to the context so httpLink can read them
  let authorization
  let anonymousId
  if (token) {
    authorization = `Bearer ${token}`
  }
  if (analytics?.user && analytics?.user()?.anonymousId) {
    anonymousId = analytics?.user()?.anonymousId()
  }
  return {
    headers: {
      ...headers,
      ...(authorization && { authorization }),
      ...(anonymousId && { 'Segment-Anonymous-Id': anonymousId }),
      'Guest-Id': guestId,
    },
  }
})

// Retry to fetch query 5 times before giving up if there is a network error
const retryLink = new RetryLink()

const client = new ApolloClient({
  link: from([authLink, retryLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
    typePolicies: {
      Query: {
        fields: {
          me: {
            merge: true,
          },
          templates: {
            keyArgs: ['organizationId', 'isDefault', 'filter', ['type', 'sort']],
            merge(existing = { data: [] }, incoming, { args }) {
              // If filter.skip is 0 or undefined we don't merge data. This is used when user refreshes the page.
              if (!args?.filter?.skip) {
                return incoming
              }
              return {
                ...incoming,
                data: [...existing.data, ...incoming.data],
              }
            },
          },
        },
      },
    },
  }),
})

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
